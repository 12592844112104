<template>
  <v-app class="not-found-wrap">
    <div
      class="not-found d-flex flex-column justify-center align-center flex-grow-1"
    >
      <div v-if="!validating && !isValid" class="v-subheader theme--light">
        This reset password link is invalid or expired
      </div>
      <div
        v-else
        class="fill-width px-3 d-flex flex-column justify-center align-center"
      >
        <h2 class="gradient-text text-center">Reset Password</h2>
        <v-card
          tile
          class="px-7 mt-5 pb-5"
          width="480"
          :loading="loading"
          :disabled="loading"
        >
          <v-form
            ref="form"
            class="pt-1"
            v-model="formValid"
            lazy-validation
            @submit.prevent="reset"
          >
            <input
              type="email"
              name="user_name"
              id="user_name"
              value=""
              class="opacity-hide"
            />
            <input
              type="password"
              name="password"
              id="password"
              value=""
              class="opacity-hide"
            />
            <v-text-field
              v-model="password"
              label="New Password"
              :persistent-hint="true"
              required
              tabindex="1"
              class="my-1 mt-4"
              type="password"
              :rules="[
                (v) =>
                  pwdValidate(v)
                    ? true
                    : v === ''
                    ? 'Required'
                    : 'Password must have uppercase, lowercase, digit, symbol and make sure the length is between 8 and 20'
              ]"
            ></v-text-field>
            <v-text-field
              v-model="confirmPassword"
              label="Confirm Password"
              :persistent-hint="true"
              required
              tabindex="1"
              class="pt-3"
              type="password"
              :rules="[(v) => (v === password ? true : 'Password mismatch')]"
            ></v-text-field>
            <div class="d-flex justify-end">
              <v-btn small tile class="primary" type="submit">Reset</v-btn>
            </div>
          </v-form>
        </v-card>
      </div>
    </div>
  </v-app>
</template>

<script>
import store from "@/store";
import helpers from "@/helpers";
import axios from "axios";
export default {
  data() {
    return {
      isValid: false,
      formValid: true,
      validating: true,
      loading: false,
      password: "",
      confirmPassword: ""
    };
  },
  computed: {},
  methods: {
    reset() {
      if (this.$refs.form.validate()) {
        const password = this.password.trim();
        this.loading = true;
        axios
          .post(
            `${process.env.VUE_APP_API_NOAUTH}/resetPassword/${this.$route.params?.resetId}`,
            {
              password
            }
          )
          .then(() => {
            store.app.showDialog(
              "Password reset successfully!",
              "Password has been reset successfully, please login using the new password",
              "success"
            );
            this.$router.replace("/");
          })
          .catch(({ response }) => {
            if (response.status === 400) {
              this.isValid = false;
            } else {
              store.app.showAlert("Some error occured!");
            }
          })
          .finally(() => (this.loading = false));
      }
    },
    pwdValidate: helpers.string.passwordCriteriaMet
  },
  mounted() {
    const resetId = this.$route.params?.resetId;
    store.app.showLoader("Please wait...");
    axios
      .get(`${process.env.VUE_APP_API_NOAUTH}/resetPassword/${resetId}`)
      .then(() => (this.isValid = true))
      .catch(({ response }) => {
        if (response.status !== 400) {
          store.app.showAlert("Some error occured!");
        }
      })
      .finally(() => ((this.validating = false), store.app.hideLoader()));
  }
};
</script>

<style lang="scss" scoped>
.not-found-wrap {
  background: none;
  overflow: hidden;
  ::v-deep .v-text-field__details {
    margin-top: -4px;
    .v-messages__message {
      font-size: 10px;
    }
  }
}
</style>
